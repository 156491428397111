import React, { useState } from 'react'
import ButtonOutline from '../../components/ButtonOutline'
import { useLangContext } from '../../context/lang.context'
import {
  gSubtitle,
  gTitle,
  gDesc,
  gInputFilled,
  gInputError,
} from '../../style/config/globals'
import { panelRegisterContent as content } from './content'
import { validateEmail } from '../../utils'
import MailingResponse from '../../components/MailingResponse'
import { s, globals, colors } from '../../style'
import { later } from '../../utils'
import {
  sErrors,
  sCheck,
  // sCheckError,
  sAgreement,
} from '../Contact/FormContact/FormContact'
import addToMailchimp from 'gatsby-plugin-mailchimp'

const PanelRegister = () => {
  const { lang } = useLangContext()

  const defaultInputValues = {
    email: '',
  }

  const defaultErrors = {
    email: false,
  }

  const [inputValues, setInputValues] = useState(defaultInputValues)
  const [agreement, setAgreement] = useState(false)
  const [agreement2, setAgreement2] = useState(false)
  const [errors, setErrors] = useState(defaultErrors)
  const [wasClicked, setWasClicked] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [success, setSuccess] = useState(null)

  const handleOnChange = (e) =>
    setInputValues({ ...inputValues, [e.target.name]: e.target.value })

  const handleSubmit = () => {
    setWasClicked(true)
    setIsLoading(true)

    let newErrors = errors

    if (!validateEmail(inputValues.email))
      newErrors = { ...newErrors, email: true }
    else newErrors = { ...newErrors, email: false }

    // if (!agreement) newErrors = { ...newErrors, agreement: true }
    // else newErrors = { ...newErrors, agreement: false }

    if (!validateEmail(inputValues.email)) {
      setErrors({ ...newErrors })
      setIsLoading(false)
      return null
    }

    if (process.env.NODE_ENV === 'development')
      later(2000)
        .then((data) => {
          setSuccess(true)
          setIsLoading(false)
          setInputValues(defaultInputValues)
          setWasClicked(false)
          setAgreement(false)
        })
        .catch((error) => {
          setSuccess(false)
          setIsLoading(false)
        })
    else
      addToMailchimp(inputValues.email)
        .then((data) => {
          setSuccess(true)
          setIsLoading(false)
          setInputValues(defaultInputValues)
          setWasClicked(false)
          setAgreement(false)
        })
        .catch((error) => {
          setSuccess(false)
          setIsLoading(false)
        })
  }

  return (
    <>
      <div css={sOuter}>
        <h1 css={gSubtitle}>{content.subtitle[lang]}</h1>
        <h2 css={gTitle}>{content.title[lang]}</h2>
        <p
          css={[
            gDesc,
            { fontSize: '0.875rem', [s.md_down]: { fontSize: '0.75rem' } },
          ]}>
          {content.desc[lang]}
        </p>
        <div css={sInputs}>
          <div css={sInputContainer}>
            <div
              css={[{ ...globals.absolute, backgroundColor: colors.container }]}
            />
            <input
              required
              name='email'
              type='email'
              placeholder={content.inputs.email[lang] + ' *'}
              value={inputValues.email}
              onChange={handleOnChange}
              css={[
                gInputFilled,
                sInput,
                errors.email &&
                  !validateEmail(inputValues.email) &&
                  gInputError,
              ]}
            />
          </div>
        </div>

        <div
          css={[
            {
              display: 'flex',
              flexDirection: 'row',
              paddingBottom: 25,
              position: 'relative',
              top: 0,
              left: 0,
            },
          ]}>
          <div
            css={[
              sCheck(agreement),
              // errors.agreement && !agreement && sCheckError,
            ]}
            onClick={() => {
              setAgreement(!agreement)
              // if (errors.agreement) setErrors({ ...errors, agreement: false })
            }}
            onKeyPress={() => {
              setAgreement(!agreement)
              // if (errors.agreement) setErrors({ ...errors, agreement: false })
            }}
            tabIndex={0}
            role='button'
            aria-label='agreement'
          />
          <p css={[sAgreement, { textAlign: 'left' }]}>
            {content.agreement[lang]}
          </p>
        </div>
        <div
          css={[
            {
              display: 'flex',
              flexDirection: 'row',
              paddingBottom: 25,
              position: 'relative',
              top: 0,
              left: 0,
            },
          ]}>
          <div
            css={[
              sCheck(agreement2),
              // errors.agreement2 && !agreement2 && sCheckError,
            ]}
            onClick={() => {
              setAgreement2(!agreement2)
              // if (errors.agreement2) setErrors({ ...errors, agreement2: false })
            }}
            onKeyPress={() => {
              setAgreement(!agreement2)
              // if (errors.agreement2) setErrors({ ...errors, agreement2: false })
            }}
            tabIndex={0}
            role='button'
            aria-label='agreement'
          />
          <p css={[sAgreement, { textAlign: 'left' }]}>
            {content.agreement2[lang]}
          </p>
        </div>
        <div
          css={[
            {
              display: 'flex',
              flexDirection: 'row',
              position: 'relative',
              top: 0,
              left: 0,
              [s.md]: {
                paddingLeft: 25,
                paddingTop: 25,
                justifyContent: 'flex-end',
                alignItems: 'center',
              },
              [s.md_only]: {
                padding: 0,
                alignItems: 'flex-end',
              },
              [s.md_down]: {
                flexDirection: 'column',
              },
            },
          ]}>
          <div
            css={[
              sErrors,
              {
                [s.lg]: { marginRight: 50 },
                [s.md_only]: { flexDirection: 'row', padding: 0 },
              },
              !wasClicked && { opacity: 0 },
            ]}>
            <p>
              {!validateEmail(inputValues.email) && content.errors.fields[lang]}
            </p>
            {/* <p>{!agreement && content.errors.agreement[lang]}</p> */}
          </div>
          <ButtonOutline
            action={handleSubmit}
            extraCss={{ [s.md_only]: { marginTop: 25 } }}
            green={validateEmail(inputValues.email) /*&& agreement*/}>
            {content.buttonSend[lang]}
          </ButtonOutline>
        </div>
      </div>
      <MailingResponse
        isLoading={isLoading}
        success={success}
        setSuccess={setSuccess}
        contentProps={content.response}
      />
    </>
  )
}

const sOuter = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  [s.md]: { textAlign: 'right' },
}

const sInputs = {
  margin: '50px 0 25px',
}

const sInput = {
  width: '100%',
  position: 'relative',
  top: 0,
  left: 0,
  margin: 0,
}

const sInputContainer = {
  width: '100%',
  position: 'relative',
  top: 0,
  left: 0,
  backgroundColor: colors.container,
  marginBottom: 25,
}

export default PanelRegister
