export const seo = {
  title: {
    pl: 'Panel',
    en: 'Panel',
  },
  description: {
    pl: 'Już teraz zarejestruj się i dołącz do CARGONITE. Mając konto, na naszej platformie, będziesz mógł na bieżąco śledzić informacje dotyczące swojego transportu oraz zobaczysz ofertę, którą dla Ciebie przygotowaliśmy.',
    en: '',
  },
}
