import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Seo from '../../components/Seo'
import { seo } from './seo'
import { useLangContext } from '../../context/lang.context'
import { globals, s, colors, alpha } from '../../style'
import { gGlow } from '../../style/config/globals'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import PanelLogin from './PanelLogin'
import PanelRegister from './PanelRegister'

const Panel = () => {
  const { lang } = useLangContext()

  const query = useStaticQuery(graphql`
    query {
      image: file(absolutePath: { regex: "/images/panel/main.jpg/" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: BLURRED, formats: [JPG])
        }
      }
    }
  `)

  return (
    <>
      <Seo
        title={seo.title[lang]}
        description={seo.description[lang]}
        lang={lang}
      />
      <div css={[sOuter]}>
        <div css={sInner}>
          <div css={sImage}>
            <GatsbyImage
              image={getImage(query.image)}
              alt='road at night'
              css={globals.absolute}
            />
            <div css={sGradient} />
          </div>
          <div css={gGlow} />
          <PanelLogin />
          <div css={sSeparator} />
          <PanelRegister />
        </div>
      </div>
    </>
  )
}

const sOuter = {
  padding: '150px 50px 150px',
  [s.sm_down]: {
    padding: '100px 50px 100px',
  },
  [s.xs]: {
    padding: '100px 0px 100px',
  },
}

const sInner = {
  animation: 'slide-panel 0.6s ease-out',
  maxWidth: globals.maxWidth - 100,
  width: '100%',
  padding: 100,
  position: 'relative',
  margin: '0 auto',
  display: 'flex',
  justifyContent: 'space-between',
  [s.md_down]: {
    padding: 50,
    // flexDirection: 'column',
  },
  [s.sm_down]: {
    flexDirection: 'column',
  },
  [s.xs]: {
    padding: 50,
  },
}

const sImage = {
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '100%',
  maxWidth: '100%',
  height: 400,
  // maxHeight: '100%',
  // [s.xs]: { aspectRatio: '1/1' },
  // [s.sm]: { aspectRatio: '3/2' },
  // [s.md]: { aspectRatio: '16/9' },
  // [s.lg]: { aspectRatio: '21/9' },
  // height: '100%',
}

const sGradient = {
  ...globals.absolute,
  background: `linear-gradient(to bottom, 
    ${colors.black},
    ${colors.black} 50%,
    ${colors.black.concat(alpha[60])} 75%,
    ${colors.black.concat(alpha[0])} 100%
    )`,
}

const sSeparator = {
  display: 'flex',

  width: 2,
  position: 'relative',
  top: 0,
  left: 0,
  backgroundColor: colors.white.concat(alpha[25]),
  margin: '0 100px',
  [s.md_only]: {
    margin: '0 50px',
  },
  [s.sm_down]: {
    margin: '50px 0',
    height: 2,
    width: '100%',
  },
}

export default Panel
