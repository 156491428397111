import React from 'react'
import Layout from '../components/Layout'
import Panel from '../page/Panel/Panel'

const PanelPage = () => (
  <Layout>
    <Panel />
  </Layout>
)

export default PanelPage
