export const panelLoginContent = {
  title: {
    pl: 'Logowanie',
    en: 'Login',
  },
  subtitle: {
    pl: 'Panel Klienta',
    en: 'Customer Panel',
  },
  inputs: {
    email: {
      pl: 'email',
      en: 'email',
    },
    password: {
      pl: 'hasło',
      en: 'password',
    },
  },
  buttonSend: {
    pl: 'Zaloguj',
    en: 'Sign in',
  },
  errors: {
    fields: {
      pl: 'Wypełnij wymagane pola',
      en: 'Fill up required fields',
    },
  },
  response: {
    success: {
      title: {
        pl: 'Konto nieaktywowane',
        en: 'Inactive account',
      },
      desc: {
        pl: 'W celu zalogowania się do platformy CARGONITE, skontaktuj się ze swoim opiekunem.',
        en: 'In order to log into the CARGONITE platform, please contact your account manager.',
      },
    },
    error: {
      title: {
        pl: '',
        en: '',
      },
      desc: {
        pl: '',
        en: '',
      },
    },
    null: {
      title: {
        pl: '',
        en: '',
      },
      desc: {
        pl: '',
        en: '',
      },
    },
  },
}

export const panelRegisterContent = {
  title: {
    pl: 'Rejestracja',
    en: 'Registration',
  },
  subtitle: {
    pl: 'Zostań klientem',
    en: 'Join CARGONITE',
  },
  desc: {
    pl: 'Już teraz zarejestruj się i dołącz do CARGONITE. Mając konto, na naszej platformie, będziesz mógł na bieżąco śledzić informacje dotyczące swojego transportu oraz zobaczysz ofertę, którą dla Ciebie przygotowaliśmy.',
    en: 'Register now and join CARGONITE. Having an account on our platform, you will be able to keep track of information about your transport and you will be notified about suggested offers.',
  },
  inputs: {
    email: {
      pl: 'email',
      en: 'email',
    },
  },

  buttonSend: {
    pl: 'Prześlij zgłoszenie',
    en: 'Sign up',
  },
  errors: {
    fields: {
      pl: 'Podany email jest niepoprawny',
      en: 'Provided invalid email',
    },
    agreement: {
      pl: 'Zaznacz zgodę',
      en: 'Check the agreement',
    },
  },
  agreement: {
    pl: 'Wyrażam zgodę na otrzymywanie drogą elektroniczną informacji handlowych w rozumieniu ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną o treści pochodzących od Cargonite Spółka z .o.o',
    en: 'I agree to receive commercial information by electronic means within the meaning of the Act of 18 July 2002 on the provision of electronic services with content from Cargonite',
  },
  agreement2: {
    pl: 'Wyrażam zgodę na wykorzystywanie przez Cargonite telekomunikacyjnych urządzeń końcowych i automatycznych systemów wywołujących celem przedstawienia informacji handlowych w rozumieniu przepisów ustawy z dnia 16 lipca 2014 r. - Prawo telekomunikacyjne.',
    en: 'I consent to the use by Cargonite telecommunications terminal equipment and automatic calling systems in order to present commercial information within the meaning of the provisions of the Act of 16 July 2014 - Telecommunications Law.',
  },
  agreementInfo: {
    pl: 'Administratorem Państwa danych osobowych będzie Cargonite Spółka z o.o. Można się z nami skontaktować w następujący sposób: listownie na adres: ul. Tęczowa 13, 53-601 Wrocław, przez e-mail: rodo@cargonite.pl lub telefonicznie: +48 453 019 266. Dane osobowe będziemy przetwarzać w celu przygotowania ofert dotyczących produktów i usług Omida. Szczegółowe informacje na temat przetwarzania danych osobowych znajdziesz tutaj.',
    en: 'The administrator of your personal data will be Cargonite Spółka z o.o. You can contact us in the following way: by letter to the following address: Tęczowa 13, 53-601 Wrocław, by e-mail: rodo@cargonite.pl or by phone: +48 453 019 266. We will process personal data in order to prepare offers regarding Omida products and services. Detailed information on the processing of personal data can be found here.',
  },
  response: {
    success: {
      title: {
        pl: 'Zgłoszenie wysłane',
        en: 'Request sent',
      },
      desc: {
        pl: 'Jeszcze tylko jeden krok! Aktywuj konto - na podany adres email przesłaliśmy dalsze instrukcje',
        en: 'One more step! Activate your account - we have sent further instructions to your mailbox',
      },
    },
    error: {
      title: {
        pl: 'Niepowodzenie',
        en: 'Wygląda na to, że złoszenie nie zostało wysłane. Skontaktuj się z nami mailowo lub telefonicznie.',
      },
      desc: {
        pl: 'Failure',
        en: 'It seems like the request has not been sent. Please, reach out to us via email or just call us',
      },
    },
    null: {
      title: {
        pl: '',
        en: '',
      },
      desc: {
        pl: '',
        en: '',
      },
    },
  },
}
