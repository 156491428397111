import React, { useState } from 'react'
import ButtonOutline from '../../components/ButtonOutline'
import { useLangContext } from '../../context/lang.context'
import {
  gSubtitle,
  gTitle,
  gInputFilled,
  gInputError,
} from '../../style/config/globals'
import {
  panelLoginContent as content,
  panelRegisterContent as contentRegister,
} from './content'
import { validateEmail } from '../../utils'
import MailingResponse from '../../components/MailingResponse'
import { s, globals, colors } from '../../style'
import { later } from '../../utils'
import { sSide, sErrors } from '../Contact/FormContact/FormContact'

const PanelLogin = () => {
  const { lang } = useLangContext()

  const defaultInputValues = {
    email: '',
    password: '',
  }

  const defaultErrors = {
    email: false,
    password: false,
  }

  const [inputValues, setInputValues] = useState(defaultInputValues)
  const [errors, setErrors] = useState(defaultErrors)
  const [wasClicked, setWasClicked] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [success, setSuccess] = useState(null)

  const validate = inputValues.password && validateEmail(inputValues.email)

  const handleOnChange = (e) =>
    setInputValues({ ...inputValues, [e.target.name]: e.target.value })

  const handleSubmit = () => {
    setWasClicked(true)
    setIsLoading(true)

    let newErrors = errors

    if (!validateEmail(inputValues.email))
      newErrors = { ...newErrors, email: true }
    else newErrors = { ...newErrors, email: false }

    if (!inputValues.password) newErrors = { ...newErrors, password: true }
    else newErrors = { ...newErrors, password: false }

    if (!validate) {
      setErrors({ ...newErrors })
      setIsLoading(false)
      return null
    }

    later(3000)
      .then((data) => {
        setSuccess(true)
        setIsLoading(false)
        setInputValues(defaultInputValues)
        setWasClicked(false)
      })
      .catch((error) => {
        setSuccess(false)
        setIsLoading(false)
        setWasClicked(false)
      })
  }

  return (
    <>
      <div css={sOuter}>
        <h1 css={gSubtitle}>{content.subtitle[lang]}</h1>
        <h2 css={gTitle}>{content.title[lang]}</h2>
        <div css={sInputs}>
          <div css={sInputContainer}>
            <div
              css={[{ ...globals.absolute, backgroundColor: colors.container }]}
            />
            <input
              required
              name='email'
              type='email'
              placeholder={content.inputs.email[lang]}
              value={inputValues.email}
              onChange={handleOnChange}
              css={[
                gInputFilled,
                sInput,
                errors.email &&
                  !validateEmail(inputValues.email) &&
                  gInputError,
              ]}
            />
          </div>
          <div css={sInputContainer}>
            <div
              css={[{ ...globals.absolute, backgroundColor: colors.container }]}
            />
            <input
              required
              name='password'
              type='password'
              placeholder={content.inputs.password[lang]}
              value={inputValues.password}
              onChange={handleOnChange}
              css={[
                gInputFilled,
                sInput,
                errors.email && !inputValues.password && gInputError,
              ]}
            />
          </div>
        </div>
        <div
          css={[
            sSide,
            {
              position: 'relative',
              top: 0,
              left: 0,
              flexDirection: 'row',
              [s.lg]: {
                justifyContent: 'space-between',
              },
              [s.md_down]: {
                width: '100%',
                flexDirection: 'column',
              },
            },
          ]}>
          <ButtonOutline
            green={validate}
            extraCss={{
              [s.md_down]: { order: 2, marginTop: 0, width: '100%' },
              [s.sm_down]: { marginTop: 0 },
            }}
            action={handleSubmit}>
            {content.buttonSend[lang]}
          </ButtonOutline>
          <div
            css={[
              sErrors,
              {
                width: '100%',
                whiteSpace: 'nowrap',
                [s.lg]: { marginLeft: 25 },
                [s.md_down]: { order: 1 },
                [s.md_only]: { marginBottom: 25 },
                [s.sm_down]: {
                  padding: 0,
                  p: { margin: 0 },
                },
              },
              !wasClicked && { opacity: 0 },
            ]}>
            {!validate && (
              <p css={{ [s.sm_down]: { paddingBottom: 25 } }}>
                {content.errors.fields[lang]}
              </p>
            )}
          </div>
        </div>
        <a
          href='https://cargonite.pl/pdf/contact-forms-pl.pdf'
          target='_blank'
          rel='noreferrer noopener'
          css={{
            color: 'white',
            display: 'flex',
            padding: '50px 0',
            fontSize: '0.675rem',
            opacity: 0.25,
            lineHeight: '1.618em',
          }}>
          {contentRegister.agreementInfo[lang]}
        </a>
      </div>
      <MailingResponse
        isLoading={isLoading}
        success={success}
        setSuccess={setSuccess}
        contentProps={content.response}
      />
    </>
  )
}

const sOuter = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}

const sInputs = {
  margin: '50px 0 25px',
  [s.md_down]: {
    margin: '25px 0 0',
  },
}

const sInput = {
  width: '100%',
  position: 'relative',
  top: 0,
  left: 0,
  margin: 0,
}

const sInputContainer = {
  width: '100%',
  position: 'relative',
  top: 0,
  left: 0,
  backgroundColor: colors.container,
  marginBottom: 25,
}

export default PanelLogin
